form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  margin-top: 40px;
}
.fake {
  display: none;
}
