$maxw: 1220px;
@mixin mainImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
}
@mixin light {
  color: rgb(49, 49, 49);
  opacity: 0.5;
}
@mixin normal {
  color: rgb(49, 49, 49);
  opacity: 1;
}

.form {
  max-width: $maxw;
  width: 100%;
  margin: 0;
  textarea:focus,
  input:focus {
    outline: none;
  }
  *:focus {
    outline: none;
  }
}
.title {
  border: none;
  font-size: 2rem;
  font-weight: 700;
  color: rgb(49, 49, 49);
  border-bottom: 1px solid black;
  padding-bottom: 5px;
  margin-bottom: 40px;
  max-width: $maxw;
  width: 100%;
  &::placeholder {
    @include light;
  }
}
.img__title {
  @include light;
  display: flex;
  gap: 5px;
  margin-bottom: 20px;
  user-select: none;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  h1 {
    display: flex;
    gap: 20px;
  }
}
.images__wrapper {
  display: grid;
  grid-template-columns: minmax(0, 800px) minmax(0, 400px);
  grid-template-rows: minmax(0, 250px) minmax(0, 250px);
  gap: 20px;
  margin-bottom: 40px;
  .select__img {
    @include mainImg;
    cursor: pointer;
    &:hover {
      background-color: rgba(218, 218, 218, 0.473);
    }
  }
  .selected__img {
    @include mainImg;
  }
  .image__wrapper:first-child {
    grid-column: 1;
    grid-row: 1 / span 2;
  }
  .image__wrapper:nth-child(2) {
    grid-column: 2;
    grid-row: 1;
  }
  .image__wrapper:last-child {
    grid-column: 2;
    grid-row: 2;
  }
}
.image__wrapper {
  position: relative;
}
.delete__img__btn {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.151);
  top: 0;
  right: 0;
  max-width: 40px;
  height: 40px;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.527);
  }
}
.description {
  padding: 20px;
  border: 1px solid black;
  font-size: 1rem;
  resize: none;
  height: 200px;
  max-width: $maxw;
}
.description__length {
  margin: 5px 0 5px auto;
}

@media (max-width: 700px) {
  .images__wrapper {
    gap: 10px;
    .image__wrapper:first-child {
      grid-column: 1 / span 2;
      grid-row: 1;
    }
    .image__wrapper:nth-child(2) {
      grid-column: 1;
      grid-row: 2;
    }
    .image__wrapper:last-child {
      grid-column: 2;
      grid-row: 2;
    }
  }
}
