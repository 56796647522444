$gap: 20px !default;
$minmax__three: minmax(0, 250px) minmax(0, 250px);
$minmax: minmax(0, 500px);
@mixin title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-wrap: break-word;
  overflow: hidden;
  position: relative;
}
@mixin image {
  margin-top: 20px;
}
@mixin edit {
  margin-left: auto;
  display: flex;
  gap: 40px;
  p {
    font-weight: bold;
    cursor: pointer;
    letter-spacing: 3px;
    font-size: 1.5rem;
    text-decoration: underline;
    text-underline-offset: 5px;
    &:hover {
      text-decoration: none;
    }
  }
  @media (max-width: 600px) {
    gap: 20px;
    p {
      font-size: 1rem;
    }
  }
}

.posts__wrapper {
  display: flex;
  flex-direction: column;
  gap: 80px;
  max-width: 1220px;
  width: 100%;
}
.post__wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    border-bottom: 1px solid black;
    width: 100%;
    bottom: -15px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.author {
  display: flex;
  align-items: baseline;
  gap: 10px;
  margin-bottom: 10px;
  p {
    font-weight: 400;
  }
  & > :first-child {
    font-size: large;
  }
  & > :last-child {
    font-weight: 400;
    font-size: small;
    color: rgb(102, 102, 102);
  }
}
.author__edit {
  display: flex;
  align-items: baseline;
  gap: 10px;
  margin-bottom: 10px;
  p {
    font-weight: 400;
  }
  & > :first-child {
    font-size: large;
  }
  & > :nth-child(2) {
    font-weight: 400;
    font-size: small;
    color: rgb(102, 102, 102);
  }
  & > :last-child {
    @include edit();
  }
}
.edit {
  @include edit();
  margin-bottom: 30px;
}
.title {
  @include title();
}
.overflow__message {
  width: min-content;
  margin-left: auto;
  font-size: 0.75rem;
  color: rgb(75, 75, 75);
  font-style: italic;
  cursor: pointer;
}
.description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 20px;
}
.images__three {
  display: grid;
  grid-template-columns: minmax(0, 800px) minmax(0, 400px);
  grid-template-rows: $minmax__three;
  gap: $gap;
  @include image();
  .img0 {
    grid-column: 1;
    grid-row: 1 / span 2;
  }
  .img1 {
    grid-column: 2;
    grid-row: 1;
  }
  .img2 {
    grid-column: 2;
    grid-row: 2;
  }
}
.images__two {
  display: grid;
  grid-template-columns: minmax(0, 600px) minmax(0, 600px);
  grid-template-rows: $minmax;
  gap: $gap;
  @include image();
  .img0 {
    grid-column: 1;
    grid-row: 1;
  }
  .img1 {
    grid-column: 2;
    grid-row: 1;
  }
}
.images__one {
  display: grid;
  grid-template-columns: minmax(0, 1220px);
  grid-template-rows: $minmax;
  @include image();
  .img0 {
    grid-column: 1;
    grid-row: 1;
  }
}

@media screen and (max-width: 700px) {
  $gap: 10px;
  $minmax__three: minmax(0, 200px) minmax(0, 200px);
  $minmax: minmax(0, 400px);
  .images__three,
  .images__two,
  .images__one {
    gap: $gap;
  }
  .images__three {
    grid-template-rows: $minmax__three;
    .img0 {
      grid-column: 1 / span 2;
      grid-row: 1;
    }
    .img1 {
      grid-column: 1;
      grid-row: 2;
    }
    .img2 {
      grid-column: 2;
      grid-row: 2;
    }
  }
  .images__two,
  .images__one {
    grid-template-rows: $minmax;
  }
}
@media screen and (max-width: 550px) {
  $gap: 10px;
  $minmax__three: minmax(0, 150px) minmax(0, 150px);
  $minmax: minmax(0, 300px);
  .images__three,
  .images__two,
  .images__one {
    gap: $gap;
  }
  .images__three {
    grid-template-rows: $minmax__three;
  }
  .images__two,
  .images__one {
    grid-template-rows: $minmax;
  }
}
