.div__input {
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: 2rem;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  input {
    height: 1.375rem;
    padding: 13px 20px;
    outline: none;
    border: 1px solid black;
    width: 100%;
    background: transparent;
    pointer-events: inherit;
  }
  label {
    position: absolute;
    transition: 0.5s;
    top: 13px;
    left: 15px;
    background: white;
    padding: 0 5px;
    color: rgb(131, 128, 128);
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
    z-index: -1;
  }
  input:focus ~ label,
  input:valid ~ label {
    top: -10px;
    left: 15px;
    font-size: 0.875rem;
    z-index: 1;
  }
  p {
    color: rgb(204, 10, 10);
    position: absolute;
    left: 0;
    bottom: -17px;
  }
  span {
    color: rgb(204, 10, 10);
  }
  .error {
    position: absolute;
    top: 105%;
    left: 20px;
    font-size: 0.75rem;
  }
  .error__input {
    border-bottom: 3px solid rgb(204, 10, 10);
  }
  .password__eye {
    position: absolute;
    right: 15px;
    top: 13px;
    cursor: pointer;
  }
}
